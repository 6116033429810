document.addEventListener("DOMContentLoaded", function() {
	"use strict";
	
	var mobileMenu = {
		showingClass: "mobilemenu-showing",
        loginMenuShowingClass: "loginmenu-showing",
		disableAnimationClass: "disable-menu-animation",
		hideMenuClass: "hidemenu",
        initFields: function() {
            if (!this.nav) {
                this.nav = document.getElementById('side-navigation');
                this.body = document.body;
                this.c = document.getElementById('body-container');
                this.inh = document.getElementById("inhalt");
                this.bar = document.getElementById("top-bar");
                this.barHeight = this.bar.getBoundingClientRect().height;
                this.toggle = document.getElementById("menutoggle");

                this.navParentAnchor = nav.parentElement;
                this.navPrevSiblingAnchor = nav.previousElementSibling;
                //Im Mobil-Modus soll im Fall, dass die Seite ein "#top-menu" enthält, das nav-Element bei geöffnetem Menü hinter vor eben dieses
                //top-Menu verschoben werden (zwecks Tabulatorreihenfolge: So folgen die Menüpunkte direkt auf den Menu-Toggle-Button, ohne dass von diesem
                //aus per Tab erst über das - teils verdeckte - Top-Menu navigieren zu müssen, um das gerade geöffnete Menü zu erreichen).
                //Beim Schließen aber wieder zurück an die ursprüngliche Position verschieben (falls das Fenster verbreitert wird): 
                //Wenn es einen prevSibling gab, dann wieder dahinter, andernfalls als erstes Kind in den bisherigen Parent verschieben. 
                //Daher speichern die beiden obigen Variablen die bisherige Default-Position, damit das Menü dorthin zurückverschoben werden kann.
                //Und die folgende Variable dient eben zum Verschieben vors Top-Menu (und zum Erkennen, ob es überhaupt existiert):
                this.topNav = document.getElementById('top-menu');
            }
        },
		init: function() {
            //Einmalige Initialisierungen nur beim _ersten_ Aufruf:
            this.initFields();
            //Re-Initialisierungen auch bei jedem Folgeaufruf:
            
			//Breite kann sich ändern, wenn sich die Viewport-Breite ändert (insb. am Rechner, aber auch am Tablet),
			//das Mobilmenü ist breiter als das Tablet-/Desktop-Menü. Daher bei jedem Init()-Aufruf neu berechnen!
			//Ausnahme: Falls das versteckte Tablet-Menü eine Breite von 0 hat (effektiv ggf. auch etwas größer), 
			//dann this.navWidth natürlich _nicht_ überschreiben!
			// (sollte dann ja _vor_ dem Verstecken gesetzt worden sein. Ansonsten, falls noch undefiniert, setze
			//  für "halbwegs" vernünftige Animation eine Konstante wie z.B. 200px ein. Das dürfte aber derzeit
			// eigentlich ohnehin nicht vorkommen, selbst bei per Cookie verstecktem Menü sollte der Init-Aufruf
			// vor dem Verstecken stattfinden. Aber so wird sichergestellt, dass diese Methode nicht mit undefiniertem
			// this.navWidth terminiert.)
			var w = this.nav.getBoundingClientRect().width;
			var marginLeft = parseInt(window.getComputedStyle(this.nav)["marginLeft"]);
			//Es gibt einen negativen Rand für Rubberband-Effekt. w soll die "Nettobreite" ohne diesen
			//negativen Rand sein, daher wird er von w abgezogen:
			if (marginLeft < 0)
				w += marginLeft;
			if (w > 100) {
				this.navWidth = w;
			} else if (!(this.navWidth > 100)) {
				this.navWidth = 200;
			}
			this.toggle.setAttribute("aria-expanded", "" + (this.showing()));
		},
		aligntop: function(alignTouchY) {
			//Argument alignTouchY ist meist undefined, außer, wenn das Menü mit einer Drag-Geste geöffnet
			//werden soll, dann ist es die Y-Koordinate/Höhe der Fingerposition und das Menü soll so ausgerichtet werden,
			//dass es nicht komplett oberhalb dieser Fingerposition geöffnet wird!
			
			this.nav.style.paddingTop = ""; //in jedem Fall zurücksetzen (bei Tablet-Mode)
			if (this.setHeight()) {
				//Ist das Menü selbst scrollbar, soll es immer ganz oben aligned sein!
				this.nav.style.top = "";
				this.nav.classList.add("top-aligned");
			} else {
				var r = this.nav.getBoundingClientRect();
				//In welchen Fällen soll überhaupt neu ausgerichtet werden und nicht an der bisherigen Position 
				//unverändert neu eingeblendet werden? Bei Mobile-Touch immer, sonst nur, wenn zuviel Menü unsichtbar wäre.
				if (alignTouchY && this.isMobile() || r.top > window.innerHeight / 3 || r.bottom < window.innerHeight * 2 / 3) {
					//Erstmal an Ursprungsposition oben zurücksetzen:
					this.nav.style.top = "";
					this.nav.classList.add("top-aligned");
					//Wenn nun der erste Menüpunkt nicht sichtbar ist, da die Seite zu weit nach unten gescrollt ist,
					//dann an oberen Fensterrand (nach unten) verschieben:
					r = this.nav.getBoundingClientRect();
					if (r.top < this.barHeight || alignTouchY) {
						//^- Wenn so weit nach oben gescrollt wurde, dass die top-bar am oberen Rand fixiert ist und das Menü
						//   aber hinter der Top-Bar verdeckt würde, dann ist r.top i.d.R. negativ (oberer Rand oberhalb von Viewport)
						//   oder zumindest < barHeight (oberer Rand des Menüs zwischen oberem Viewportrand (0) und Unterkante top-bar)!
						//   Nur in dem Fall überhaupt neu anordnen -- oder bei Touch-Drag, wo das Menü ggf. dennoch tiefer
						//   angeordnet werden soll (zentriert um Finger), selbst wenn es oben unter die Bar passen würde.
					
						//Bei Mobilansicht Menü am unteren Rand des ggf. fixierten Top-Bars ausrichten.
						//Im Tablet-Modus dagegen würde damit das Menü immer zu tief gerückt, das Menü soll dann vielmehr
						//direkt am oberen Bildschirmrand arrangiert werden, wozu nur das Delta aus Scrollposition
						//abzüglich aktueller Top-Position (Abstand vom oberen Seitenrand, wenn in Default-Position)
						//berechnet wird:
						if (this.isTablet()) { 
							//Bei float:left geht keine Positionierung per Top, 
							//vielmehr werde ich das Padding vergrößern:
							this.nav.style.paddingTop = (-1 * r.top + this.barHeight) + "px";
						} else {
							var sr = this.c.getBoundingClientRect();
							this.nav.classList.remove("top-aligned");
							var topAlignedY = -1 * sr.top + this.barHeight;
							//(sr.top ist negativ und gibt an, wie weit der Containerinhalt nach oben aus dem Viewport herausgescrollt ist.)
							if (typeof alignTouchY === "number") {
								//Zunächst mal um TouchY vertikal zentrieren...
								var top = alignTouchY - Math.floor(0.5 * r.height) - sr.top;
								//Wenn es damit höher rücken würde als der untere top-bar-Rand, dann doch am top-bar ausrichten:
								//In diesem Fall kann es sein, dass die Top-bar nicht bereits am Viewportrand "klebt", sondern
								//darüber noch Header sichtbar ist. Daher muss zu topAlignedY hier noch die aktuelle Top-Position
								//des top-bar addiert werden.
								topAlignedY += this.bar.getBoundingClientRect().top;
								if (top < topAlignedY)
									top = topAlignedY;
								this.nav.style.top = top + "px";
							} else {
								//Standardfall: Nicht per Touch-Geste geöffnet. Top-bar ist bereits am oberen Fensterrand fixiert,
								//d.h. es genügt eine Ausrichtung an oberem Viewportrand + barHeight.
								//topAlignedY stimmt in diesem Fall also bereits.
								this.nav.style.top = topAlignedY + "px";
							}
						}
					}
				}
			}
		},
		setHeight: function() {
			//Falls das CSS der Seite explizit vertikales Scrollen im Menue aktiviert hat (overflow-y im CSS), dann passe Hoehe an Content an und 
			//gib true zurueck, falls der Menüinhalt nicht mehr rein passt, es also scrollable geworden ist.
			if (window.getComputedStyle(this.nav).overflowY !== "scroll") {
				return false;
			} else {
				this.nav.style.height = window.getComputedStyle(this.inh).height;
				return this.nav.clientHeight < this.nav.scrollHeight;
			}
		},
		isVisible: function(elem) { //borrowed from jQuery :visible selector source
			return !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length );
		},
		isMobile: function() {
//			return this.isVisible(document.getElementById("backlink"));
//			Die obige Funktion schlägt auf Seiten ohne Backlink (z.B. Hilfeseiten zu Bewertern) fehl!
//			return window.getComputedStyle(this.nav).float === "none";
//			computedStyle.float ist nicht mit IE kompatibel (undefined)! 
			return window.getComputedStyle(this.nav).position === "absolute"; //Bei Tablet ist die position "static"
		},
		isTablet: function() {
			return !this.isMobile() && this.isVisible(document.getElementById("menubutton"));
		},
		setTabletCookie: function(hidemenu) {
            sessionStorage.setItem("hidemenu", "" + hidemenu);
		},
		getTabletCookieIsHideMenu: function() {
			var cookieValue = sessionStorage.getItem("hidemenu");
			return cookieValue === "true";
		},
		initTablet: function() {
			this.init();
			if (this.isTablet() && !this.hasShowingClass() && this.getTabletCookieIsHideMenu()) {
				//Im Tablet-Modus, wenn nicht bereits vom Server die mobilemenushowing-Class gesetzt wurde, die eine Menüanzeige erzwingt,
				//dann ggf. abhängig von Cookie das Menü beim Laden der Seite ausblenden.
				this.disableMenuAnimation(); //Damit beim initialen "hide" nicht immer eine Animation abgespielt wird.
				//var b = this.body; //Für Closure, da "this" im Timeout-Handler anders definiert ist.
				//var dac = this.disableAnimationClass; //Dito
				//b.classList.add(this.hideMenuClass); //Erstmal Menü ausblenden, während noch "disableMenuAnimation" auch als Klasse gesetzt ist.
                this.body.classList.add(this.hideMenuClass);
                var me = this; //Für Closure, da "this" im Timeout-Handler anders definiert ist.
				window.setTimeout(function() {
					//b.classList.remove(dac); //Erst dann die disableMenuAnimation-Klasse wieder entfernen.
                    me.enableMenuAnimation();
				}, 500); //Animation etwa 0.5 sec später wieder einschalten.
			}
		},
        moveNavBeforeTopNav: function() {
            if (this.topNav !== null) {
				//Speziell im Mobile-Mode soll das explizit vom User geöffnete Popup-Menü in der Tab-Reihenfolge 
				//direkt auf den Toggle folgen, also im Zweifel _vor_ dem Top-Menu stehen.
                //Dazu wird es im DOM-Tree verschoben, sofern überhaupt ein Top-Menu existiert:
                this.topNav.insertAdjacentElement("beforebegin", this.nav);
            }
        },
        moveNavBack: function() {
            //Wieder zurück verschieben. Eigentlich nur für den Fall isMobile() gedacht, aber unabhängig davon ausführen, falls die Seite mit geöffnetem
            //Mobildesign nachträglich verbreitert wurde
            if (this.topNav !== null && this.topNav.previousElementSibling === this.nav) {
                //Wenn nav im Original-Doc das erste Kind seines Parents war, füge es wieder neu als erste Kind im parentAnchor ein,
                //andernfalls, d.h. wenn es im parent-Element einen previousSibling hatte, dann wieder hinter diesem previousSiblingAnchor einfügen.
                //Unter Vernachlässigung von Text-Nodes (die hier in der Struktur allenfalls Whitespaces enthalten sollten) sollte das dann wieder
                //der Urpsrungsposition (Anchor) entsprechen, also die Effekte von moveNavBeforeTopNav() zurückgenommen werden.
                if (this.navPrevSiblingAnchor !== null) 
                    this.navPrevSiblingAnchor.insertAdjacentElement("afterend", nav);
                else
                    this.navParentAnchor.insertAdjacentElement("afterbegin", nav);
            }
        },
        toggleNavPositionOnViewportResize: function() {
            this.initFields(); //Die Rest-Initialisierung, die bei jedem init()-Aufruf stattfindet, wird in DIESEM Fall nicht benötigt.
            if (this.isMobile())
                this.moveNavBeforeTopNav();
            else
                this.moveNavBack();
        },
		show: function(altop) {
			this.removeHideMenuClass(); //Auch im Mobile-Fall, falls auf Desktop ein Fenster von Tablet-Große bei ausgeblendetem
			//Menü auf Mobile-Größe verkleinert wird!
			if (this.isMobile()) {
				this.setShowingClass();
			} else if (this.isTablet()) {
				this.setTabletCookie(false);
			}
			this.removeDragStyles();
		
			if (altop) {
				this.aligntop();
			}
			this.toggle.setAttribute("aria-expanded", "true");
		},
		hide: function() {
			this.removeShowingClass();
			if (this.isTablet()) {
				this.setHideMenuClass();
				this.setTabletCookie(true);
			}
			this.removeDragStyles();
			this.toggle.setAttribute("aria-expanded", "false");
		},
		hasShowingClass: function() {
			//Ist a) der Fall, wenn per JS ein Mobilmenu eingeblendet wurde und
			//b), wenn schon vor Seitenauslieferung die Menueanzeige auch im Mobile- und Tablet-Mode (unabh. v. Cookie) erzwungen werden soll.
			return this.body.classList.contains(this.showingClass);
		},
		setShowingClass: function() {
            this.body.classList.remove(this.loginMenuShowingClass); //Falls das Login-Overlaymenü offen sein sollte, schließe das zunächst.
			this.body.classList.add(this.showingClass);
		},
		removeShowingClass: function() {
			this.body.classList.remove(this.showingClass);
		},
		hasHideMenuClass: function() {
			return this.body.classList.contains(this.hideMenuClass);
		},
		setHideMenuClass: function() {
			this.body.classList.add(this.hideMenuClass);
		},
		removeHideMenuClass: function() {
			this.body.classList.remove(this.hideMenuClass);
		},
		showing: function() {
			var mobile = this.isMobile();
			return (mobile && this.hasShowingClass()) || (!mobile && !this.hasHideMenuClass());
		},
		click: function() {
			this.init();
			if (this.showing()) {
				this.hide();
			} else {
				this.show(true);
			}
		},
		disableMenuAnimation: function() {
			this.body.classList.add(this.disableAnimationClass);
		},
		enableMenuAnimation: function() {
			this.body.classList.remove(this.disableAnimationClass);
		},
		begindrag: function(y) {
			this.init();
			if (this.isMobile() || this.isTablet()) {
				if (!this.showing()) {
					this.aligntop(y);
				} 
				this.disableMenuAnimation();
			}
		},
		drag: function(dist, open) {
			if (open && !this.showing()) {
				var threshold = this.navWidth;
				if (this.isMobile()) {
					if (dist < threshold) {
						this.nav.style.left = (dist - this.navWidth) + "px";
					} else {
						this.nav.style.left = (threshold - this.navWidth + Math.min(dist - threshold, 8 * Math.log2(2 + (dist - threshold)))) + "px";
						//Notiz: Bei Mobile-Menu mit 8-fachem Log ist das "Gummiband etwas elastischer" als
						//unten bei Tablet-Menu (kein Overlay), wo ein deutlich dezenterer Gummibandeffekt
						//mit "strafferem Gummi", also viel kleinerem Ausschlag gewählt wurde (5-facher Log).
					}
				} else if (this.isTablet()) {
					if (dist < threshold) {
						this.nav.style.width = dist + "px";
					} else {
						this.nav.style.width = this.navWidth + "px";
						this.inh.style.paddingLeft = 	(/*this.c.clientWidth - threshold - */ Math.min(dist - threshold, 5 * Math.log2(2 + (dist - threshold)))) + "px";
					}
					this.nav.style.opacity = Math.min(1, 1 - ((threshold - dist) / threshold));
				}
			} else if (!open && this.showing() && (this.isMobile() || this.isTablet()) ) {
				if (this.isMobile()) {
					this.nav.style.left = "" + dist + "px";
				} else if (this.isTablet()) {
					this.nav.style.width = (this.navWidth + dist) + "px";
					this.nav.style.opacity = Math.max(0, 1 + (dist / this.navWidth));
				}
			}
		},
		removeDragStyles: function () {
			this.enableMenuAnimation();
			this.nav.style.left = "";
			this.nav.style.width = "";
			this.nav.style.opacity = "";
			this.inh.style.paddingLeft = "";
		},
		enddrag: function(dist, open, e) {
			if (this.isMobile() || this.isTablet()) {
				var trigger = this.navWidth / 3;
				if (open) { //Öffnen-Geste: Swipe right on #inhalt
					if (dist > trigger) {
						//Hinreichende Öffnen-Geste
						e.preventDefault();
						this.show(false);
						//überschreibt _zuerst_ das class-Attribut und aktiviert somit die Animation wieder, 
						//erst _danach_ wird das style="left:xxx"-Attribut entfernt und somit zum CSS-regelbasierten 0px-Left-Style zurückgekehrt
						//und somit das Menü animiert zu Ende eingeblendet.
					} else if (!this.showing()) {
						//Nicht hinreichende Öffnen-Geste, nur bei geschlossenem Menü auswerten,
						//d.h. begonnene Touches auf dem Inhalt neben/über/unter dem geöffneten Menü ignorieren.
						this.hide();
					}
				} else { //Schließ-Geste: Swipe left on #navigation
					if (dist * (-1) > trigger) {
						//Hinreichende Schließ-Geste:
						e.preventDefault();
						this.hide();
						//Entfernt _zuerst_ die Class-Names und und aktiviert somit die Animation wieder, 
						//erst _danach_ wird das style="left:xxx"-Attribut entfernt und somit zum CSS-regelbasierten -400px-Left-Style zurückgegehrt
						//und somit das Menü animiert versteckt.
					} else {
						//Nicht hinreichende Schließ-Geste
						//In diesem Fall per show() das Menü wieder ganz ausfahren, aber nicht preventDefault ausführen, weil sonst
						//auch alle Klicks (per Touch) im Menü nicht mehr funktionierten!
						this.show(false);
						//Parameter false bewirkt, dass das Menü nicht neu vertikal aligned wird.
						//Das ist sehr wichtig, damit Klicks auf Menüpunkte korrekt funktionieren, wenn im Menü nach unten gescrollt wird.
						//Ein Re-Align durch die Show-Methode konnte noch vor Auslösen des Click-Events das Menü unterm Finger weg verschieben,
						//so dass das Click-Event auf einem ganz anderen Menüpunkt ausgelöst wird!
					}
				}
			}
		},
		canceldrag: function() {
			this.removeDragStyles();
		}
	};
	
	//Abschneiden eines trailing slashes von einem URL
	//Falls url undefined oder null ist, wird er ebenso unverändert ausgegeben wie ein String ohne trailing slash.
	function trimSlash(url) {
		if (url && url.charAt(url.length-1) === '/') 
			return url.substring(0, url.length - 1);
		else
			return url;
	}
	
	function autoSelectLi() {
		//Menüpunkt in als "autoselect" markierter Menügruppe ggf. automatisch anhand Document-URL selektieren:
		var l = window.location;
		var docurl = l.protocol + '//' + l.host + trimSlash(l.pathname);
		var lis = document.querySelectorAll("#side-navigation ul.autoselect li");
		var selectedNoQuery = [];
		//Herkömmliche For-Schleife für IE-Kompatibilität (NodeList.forEach() in IE nicht implementiert):
		for (var i=0, len=lis.length; i < len; i++) {
			var li = lis[i];
			var link = li.querySelector("a");
			if (link) {
				var parts = link.getAttribute("href").split("?");
				var linkurl = trimSlash(parts[0]);
				if (linkurl === docurl && (parts.length === 1 || "?" + parts[1] === l.search)) {
					li.classList.add("selected");
//					link.setAttribute("aria-label", "Aktuelle Seite " + link.textContent + " neu laden");
					link.setAttribute("aria-current", "page");
					if (parts.length === 1 && l.search)
						selectedNoQuery.push(li);
					else if (parts.length > 1 && selectedNoQuery.length) {
						//Der aktuelle Seiten-URL enthält einen Query-String. Dennoch wurde zunächst min.
						//ein Menüpunkt ohne QueryString selektiert (was erstmal das Defaultverhalten ist).
						//Aber nun wurde ein "spezifischerer" Menüpunkt mit genau diesem QueryString im Menü
						//gefunden und ebenfalls selektiert.
						//In diesem Fall sollen die zuvor selektierten allgemeineren Menüpunkte ohne QueryString
						//deselektiert werden:
						for (var j=0, lenj=selectedNoQuery.length; j < lenj; j++) {
							var li2 = selectedNoQuery[j];
							li2.classList.remove("selected");
							var link2 = li2.querySelector("a");
//							link2.removeAttribute("aria-label");
							link2.removeAttribute("aria-current");
						}
					}
				}
			}
		}
	}
	
	function isScrolledToRight(node) {
		return node.scrollLeft > 0;
	}
	
	function shouldSupportScrollSwipeRight(event) {
		var e = event.target;
		while (!isScrolledToRight(e) && e !== event.currentTarget && e.parentNode !== null) {
			e = e.parentNode;
		}
		return isScrolledToRight(e);
	}
	
	function hideScrollToTop() {
		var c = window.getComputedStyle(document.getElementById("body-container"));
		var st = document.getElementById("scrollToTop");
		if (st && c.height === c.minHeight) {
			st.style.display = "none";
		}
	}
	
	//**** BEGIN ****
    
    //Prüfe, ob Passive-Eventlisteners (für TouchEvents) unterstützt werden:
    //Quelle: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md (Verlinkt von Google Chrome DevTools):
    // Test via a getter in the options object to see if the passive property is accessed
    var supportsPassive = false;
    try {
        var opts = Object.defineProperty({}, 'passive', {
            get: function() {
                supportsPassive = true;
            }
        });
        window.addEventListener("testPassive", null, opts);
        window.removeEventListener("testPassive", null, opts);
    } catch (e) {"ignore";}

    // Use our detect's results. passive applied if supported, capture will be false either way.
    const passiveEvent = supportsPassive ? { passive: true } : false; 
    //Dieses Objekt ist an vorzugsweise passive Eventlistener (die kein ev.preventDefault() aufrufen dürfen) als 3. Argument zu übergeben.
	

	
	var i = document.getElementById("body-container");
	var nav = document.getElementById("side-navigation");
    var topnav = document.getElementById("top-menu");
	var menubtn = document.getElementById("menutoggle");
    
    if (nav === null || menubtn === null)
        window.console.info("No side navigation menu found in this page");
    else {
        autoSelectLi();
        if (topnav !== null) {
            //Die mobileMenu.toggle...-Methode selbst taugt nicht als Eventhandler, weil im Event-Handler-Call die Variable "this" dann nicht auf das mobileMenu-Objekt zeigen würde.
            window.addEventListener("resize", function() {
                mobileMenu.toggleNavPositionOnViewportResize();
            }, passiveEvent);
            //Einmalig sofort ausführen, falls die Seite direkt auf einem Mobile geladen wird, also die Mobile-Menüposition erwartet wird, auch wenn
            //gar kein Resize- oder OrientationChange-Event seit dem Laden der Seite stattgefunden hat:
            window.addEventListener("load", function() {
                mobileMenu.init();
                mobileMenu.toggleNavPositionOnViewportResize();
            });
        }
    
        let dragging = false;
        let startX;
        let startY;
        function touchstart(e) {	
            if (window.pageXOffset <= 0 && e.changedTouches.length === 1) {
                const touchobj = e.changedTouches[0];
                if (this === nav || (touchobj.pageX < window.innerWidth / 3 && !shouldSupportScrollSwipeRight(e) )) { 
                    //Beim auf dem leeren Screen gestarteten Event muss der Drag im linken Bildschirmdrittel begonnen werden,
                    //ein (Schließen-)Drag, das auf dem geöffneten Menü beginnt, darf auf der gesamten Menü-Panel-Fläche gestartet werden.
                    //Sollte an der Stelle jedoch eine Swipe-Right-Geste ein Zurückscrollen nach links bedeuten, dann ignoriere dieses
                    //Event komplett, um nicht beim Scrollen das Menü zu öffnen.
                    startX = touchobj.clientX;
                    startY = touchobj.clientY;
                    dragging = true;
                    mobileMenu.begindrag(startY);
                }
            }
        }
        try {
            menubtn.addEventListener("click", function(ev) {
                ev.preventDefault(); 
                mobileMenu.click();
            });
            //indirekter Aufruf "mobileMenu.click()" immerhalb lokaler Funktion, damit innerhalb des click-Handlers
            //"this" an "mobileMenu" gebunden wird.
            //mobileMenu.click darf nicht (als Funktionsreferenz übergeben) direkt als Handler fungieren,
            //da "this" im Handler den Absender (Link menubtn) referenziert.
            nav.classList.add("js-enabled");
            document.getElementById("fu-content").classList.add("js-enabled");
            mobileMenu.initTablet();
        } catch (ex) {
            console.error(ex);
        }

        i.addEventListener("touchstart", touchstart, passiveEvent);
        nav.addEventListener("touchstart", touchstart, passiveEvent);

        var touchmove = function(e){
            if (dragging) {
                var touchobj = e.changedTouches[0];
                var distX = touchobj.clientX - startX;
                var distY = touchobj.clientY - startY;
                if (distY < 50 && distY > -50 && e.changedTouches.length === 1) {
                    if (this === i && distX > 0) {
                        mobileMenu.drag(distX, true);
                    } else if (this === nav && distX < 0) {
                        mobileMenu.drag(distX, false);
                    }
                } else {
                    mobileMenu.canceldrag();
                    dragging = false;
                }
            }
        };
        i.addEventListener("touchmove", touchmove, passiveEvent);
        nav.addEventListener("touchmove", touchmove, passiveEvent);

        var touchend = function(e) { 
            if (dragging) {
                var touchobj = e.changedTouches[0];
                var distX = touchobj.clientX - startX;
                mobileMenu.enddrag(distX, this === i, e);
                dragging = false;
            }
        };
        i.addEventListener("touchend", touchend); //NICHT passive, da e.preventDefault aufgerufen werden kann!
        nav.addEventListener("touchend", touchend);

        var touchcancel = function() {
            mobileMenu.canceldrag();
            dragging = false;
        };
        i.addEventListener("touchcancel", touchcancel, passiveEvent);
        nav.addEventListener("touchcancel", touchcancel, passiveEvent);
        
        nav.addEventListener("keydown", (ev) => {
           if (ev.key === "Escape" && mobileMenu.isMobile() && mobileMenu.showing()) {
               mobileMenu.hide();
               menubtn.focus();
           }
        });

        //Off-Topic: Scripte, die nur auf Seiten mit Top-Bar und Body-Container ausgeführt werden sollen.
        //Der Einfachheit halber mit im selben Scriptfile, weil dieses Script inzwischen auf praktisch allen betroffenen Seiten
        //geladen wird (Ausnahme: Seiten ohne Seitenmenü und ohne Loginmenü)
    }

    window.setTimeout(hideScrollToTop, 200); //Mit Verzögerung, falls noch Seiteninhalte per JS rendern (wie Chartist-Charts z.B.) und die Seite noch wächst.


    //Funktion, damit beim Fokussieren eines von der Top-Bar verdeckten Links (oder Inputs / Buttons / Selects) dieser in den Viewport gescrollt wird:
    const topbar = document.getElementById("top-bar");
    document.querySelectorAll("#body-container a, #body-container input, #body-container button, #body-container select, #body-container textarea")
        .forEach(el =>
            el.addEventListener("focus", ev => {
                const el = ev.target;
                const {top} = el.getBoundingClientRect();
                const {bottom} = topbar.getBoundingClientRect();
                if (top < bottom) {
                    window.scrollTo({top: window.pageYOffset - bottom + top - 10, behavior: 'instant'});
                }
            })
        );
    
});